import { FC } from 'react'
import TSvgProps from 'svgIcons/type/svgPropsType'

interface MenuIconProps extends TSvgProps {
  isActive: boolean
}

const MenuIcon: FC<MenuIconProps> = ({ isActive, ...props }) => {
  return isActive ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <desc>메뉴 아이콘 활성화</desc>
      <g fill="none" data-name="icon / 24 / more">
        <path d="M0 0h24v24H0z" data-name="사각형 29534"></path>
        <g
          stroke="#333"
          data-name="사각형 30350"
          transform="translate(3.508 4.633)"
        >
          <rect width="16.986" height="1.916" stroke="none" rx="0.958"></rect>
          <rect width="15.986" height="0.916" x="0.5" y="0.5" rx="0.458"></rect>
        </g>
        <g
          stroke="#333"
          data-name="사각형 30351"
          transform="translate(3.508 11.041)"
        >
          <rect width="13.34" height="1.916" stroke="none" rx="0.958"></rect>
          <rect width="12.34" height="0.916" x="0.5" y="0.5" rx="0.458"></rect>
        </g>
        <g
          stroke="#333"
          data-name="사각형 30352"
          transform="translate(3.508 17.451)"
        >
          <rect width="16.986" height="1.916" stroke="none" rx="0.958"></rect>
          <rect width="15.986" height="0.916" x="0.5" y="0.5" rx="0.458"></rect>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <desc>메뉴 아이콘 비활성화</desc>
      <g fill="none" data-name="icon / 24 / more">
        <path d="M0 0h24v24H0z" data-name="사각형 29534"></path>
        <g
          stroke="#888"
          data-name="사각형 30350"
          transform="translate(3.508 4.633)"
        >
          <rect width="16.986" height="1.916" stroke="none" rx="0.958"></rect>
          <rect width="15.986" height="0.916" x="0.5" y="0.5" rx="0.458"></rect>
        </g>
        <g
          stroke="#888"
          data-name="사각형 30351"
          transform="translate(3.508 11.041)"
        >
          <rect width="13.34" height="1.916" stroke="none" rx="0.958"></rect>
          <rect width="12.34" height="0.916" x="0.5" y="0.5" rx="0.458"></rect>
        </g>
        <g
          stroke="#888"
          data-name="사각형 30352"
          transform="translate(3.508 17.451)"
        >
          <rect width="16.986" height="1.916" stroke="none" rx="0.958"></rect>
          <rect width="15.986" height="0.916" x="0.5" y="0.5" rx="0.458"></rect>
        </g>
      </g>
    </svg>
  )
}

export default MenuIcon
