import { TabBarItemProps } from 'app.layout/mobile/TabBar/TabBarItem'
import CalenderIcon from 'svgIcons/tabBar/CalendarIcon'
import ChattingIcon from 'svgIcons/tabBar/ChattingIcon'
import CommunityIcon from 'svgIcons/tabBar/CommunityIcon'
import HomeIcon from 'svgIcons/tabBar/HomeIcon'
import MenuIcon from 'svgIcons/tabBar/MenuIcon'

interface GetTabBarDataParameter {
  activedTabPathname: string
}

interface TabBarDataType {
  menu: TabBarItemProps
  community: TabBarItemProps
  home: TabBarItemProps
  calendar: TabBarItemProps
  chatting: TabBarItemProps
}

const getTabBarData = ({
  activedTabPathname,
}: GetTabBarDataParameter): TabBarDataType => {
  const TAB_BAR_DATA: TabBarDataType = {
    menu: {
      icon: <MenuIcon isActive={activedTabPathname === '/nav'} />,
      isActive: activedTabPathname === '/nav',
      label: '메뉴',
    },
    community: {
      icon: <CommunityIcon isActive={false} />,
      isActive: false,
      label: '커뮤니티',
    },
    home: {
      icon: <HomeIcon isActive={activedTabPathname === '/'} />,
      isActive: activedTabPathname === '/',
      label: '홈',
    },
    calendar: {
      icon: <CalenderIcon isActive={activedTabPathname === '/calendar'} />,
      isActive: activedTabPathname === '/calendar',
      label: '공고달력',
    },
    chatting: {
      icon: <ChattingIcon isActive={false} />,
      isActive: false,
      label: '채팅',
    },
  }

  return TAB_BAR_DATA
}

export default getTabBarData
