import { FC, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import ChatAppModal from '@app.components/modals/ChatAppModal/ChatAppModal'
import getTabBarData from 'app.layout/module/getTabBarData'
import { XEN_HOSTNAME } from 'utils/config'
import TabBarItem from './TabBarItem'

interface TabBarMobileProps {}

const TabBarMobile: FC<TabBarMobileProps> = () => {
  const router = useRouter()
  const [isOpenChatAppModal, setIsOpenChatAppModal] = useState<boolean>(false)
  const { calendar, chatting, community, home, menu } = getTabBarData({
    activedTabPathname: router.pathname,
  })

  const handleOpenChatAppModal = () => {
    setIsOpenChatAppModal(true)
  }

  const handleCloseChatAppModal = () => {
    setIsOpenChatAppModal(false)
  }

  return (
    <StyledWrapper>
      <Link href="/nav">
        <a className="tab-bar-menu">
          <TabBarItem {...menu} />
        </a>
      </Link>
      <Link href={`https://${XEN_HOSTNAME}/community`}>
        <a className="tab-bar-menu">
          <TabBarItem {...community} />
        </a>
      </Link>
      <Link href="/">
        <a className="tab-bar-menu">
          <TabBarItem {...home} />
        </a>
      </Link>
      <Link href="/calendar">
        <a className="tab-bar-menu">
          <TabBarItem {...calendar} />
        </a>
      </Link>
      <TabBarItem
        {...chatting}
        onClick={handleOpenChatAppModal}
        className="tab-bar-menu"
      />
      <ChatAppModal
        open={isOpenChatAppModal}
        onClose={handleCloseChatAppModal}
      />
    </StyledWrapper>
  )
}

export default TabBarMobile

const StyledWrapper = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 29;
  width: 100%;
  height: 54px;
  background-color: #fff;
  border-top: 1px solid #eeeeee;
  display: flex;

  .tab-bar-menu {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
